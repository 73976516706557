import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./AboutRice.css";

export default function AboutAgriRice() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="agriRice-layout-42">
        <div className="agriRice-content2">
          <div
            className="agriRice-column2"
            data-aos="fade-right"
            data-aos-duration="700"
          >
            <div className="agriRice-heading">Quality Rice</div>
          </div>
          <div className="agriRice-column3">
            <div className="agriRice-text">
              <p
                className="agriRice-this-was-done"
                data-aos="fade-left"
                data-aos-duration="500"
              >
                The “CIC Golden Crop ‘’ label is not only one of the most
                recognizable Rice brands in Sri Lanka, but also the only brand
                to offer a unique range of specialty rice varieties, including
                nutritionally enriched Health Rice, authentic Sri Lankan
                Traditional Rice and highly palatable Specialty Rice. The result
                of extensive in-house research, these novel formulations all
                contain authentic flavour and texture profiles as well as their
                own unique health and nutritional benefits. Our most recent
                innovation - the Purple Queen rice is currently in the process
                of being market tested both in Sri lanka and overseas.
              </p>
              <p className="agriRice-this-was-done">&nbsp;</p>
              <p
                className="agriRice-this-was-done"
                data-aos="fade-left"
                data-aos-duration="700"
              >
                All rice varieties are grown locally in our own seed farms or
                sourced through our network of exclusive out grower partners.
                Rice is processed at our state-of-the-art, globally certified
                milling complex at Maho. “CIC Golden Crop” Rice is made
                available to consumers across the island through our modern
                trade partners.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
