import React from "react";
import "./OurInvestmentInRice.css";
import RDPelwehera from "../../../../images/R&DPelwehera.png";
import RiceMilling from "../../../../images/RiceMilling.png";
import SeedsPaddy from "../../../../images/SeedsPaddy.png";
import Rice from "../../../../images/OutGrowers-Rice.png";
import Dairy from "../../../../images/StrongFarmerNetwork-Dairy.png";
import Seed from "../../../../images/OutGrowers–Seed.png";

const OurInvestmentInRice = () => {
  return (
    <div className="ourInvestmentInRice-Main">
      <h1
        className="ourInvestmentInRice-title"
        data-aos="fade-down"
        data-aos-duration="700"
      >
        Our Investment in the RICE Segment
      </h1>
      <div className="ourInvestmentInRice-columns">
        {/* Column 1 */}
        <div className="ourInvestmentInRice-column">
          <img
            src={RDPelwehera}
            alt="R&D"
            className="ourInvestmentInRice-image"
            data-aos="zoom-in"
            data-aos-duration="700"
          />
          <h2
            className="ourInvestmentInRice-subtitle"
            data-aos="zoom-in"
            data-aos-duration="700"
            data-aos-delay="200"
          >
            R&D AT PELWEHERA
          </h2>
          <table
            className="ourInvestmentInRice-table"
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <tbody>
              <tr>
                <td className="ourInvestmentInRice-bold">LOCATION</td>
                <td className="ourInvestmentInRice-bold">ACRES</td>
              </tr>
              <tr>
                <td>HINGURAKGODA</td>
                <td className="ourInvestmentInRice-bold">1300</td>
              </tr>
              <tr>
                <td>PELWEHERA</td>
                <td className="ourInvestmentInRice-bold">832</td>
              </tr>
              <tr>
                <td>TALAWA</td>
                <td className="ourInvestmentInRice-bold">172</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Column 2 */}
        <div className="ourInvestmentInRice-column">
          <img
            src={RiceMilling}
            alt="Rice Milling"
            className="ourInvestmentInRice-image"
            data-aos="zoom-in"
            data-aos-duration="700"
          />
          <h2
            className="ourInvestmentInRice-subtitle"
            data-aos="zoom-in"
            data-aos-duration="700"
            data-aos-delay="200"
          >
            RICE MILLING COMPLEX AT MAHO
          </h2>
          <table
            className="ourInvestmentInRice-table"
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <tbody>
              <tr>
                <td className="ourInvestmentInRice-bold">LOCATION</td>
                <td className="ourInvestmentInRice-bold">CAPACITY</td>
              </tr>
              <tr>
                <td>MAHO</td>
                <td className="ourInvestmentInRice-bold">
                  500 Mt <span className="ourInvestmentInRice-normal">p.a</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  CERTIFICATIONS <br />
                  <span className="ourInvestmentInRice-bold">
                    ISO 9001:2016
                  </span>
                  <br />
                  <span className="ourInvestmentInRice-bold">
                    ISO 22000-2018
                  </span>
                  , including <br /> GMP, HACCP, Halal, US FDA
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Column 3 */}
        <div className="ourInvestmentInRice-column">
          <img
            src={SeedsPaddy}
            alt="Seed Paddy"
            className="ourInvestmentInRice-image"
            data-aos="zoom-in"
            data-aos-duration="700"
          />
          <h2
            className="ourInvestmentInRice-subtitle"
            data-aos="zoom-in"
            data-aos-duration="700"
            data-aos-delay="200"
          >
            SEEDS PADDY PROCESSING PLANTS
          </h2>
          <table
            className="ourInvestmentInRice-table"
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <tbody>
              <tr>
                <td className="ourInvestmentInRice-bold">LOCATION</td>
                <td className="ourInvestmentInRice-bold">CAPACITY</td>
              </tr>
              <tr>
                <td>HINGURAKGODA</td>
                <td className="ourInvestmentInRice-bold">100BU/hr</td>
              </tr>
              <tr>
                <td>MAHO</td>
                <td className="ourInvestmentInRice-bold">80BU/hr</td>
              </tr>
              <tr>
                <td>PELWEHERA</td>
                <td className="ourInvestmentInRice-bold">80BU/hr</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p
        className="ourInvestmentInRice-phara"
        data-aos="zoom-out"
        data-aos-duration="700"
        data-aos-delay="300"
      >
        “CIC Golden Crop’’ Rice is also exported to selected overseas markets in
        Europe, USA, Canada and Australia.
      </p>
      <div
        className="ourInvestmentInRice-columns"
        data-aos="fade-up"
        data-aos-duration="800"
        data-aos-delay="400"
      >
        {/* Column 1 */}
        <div className="ourInvestmentInRice-column">
          <img src={Rice} alt="R&D" className="ourInvestmentInRice-image-1" />
          <table className="ourInvestmentInRice-table-1">
            <tbody>
              <tr>
                <td>
                  <span className="ourInvestmentInRice-bold">1,500</span>
                  <br /> Out growers - Rice
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Column 2 */}
        <div className="ourInvestmentInRice-column">
          <img
            src={Dairy}
            alt="Rice Milling"
            className="ourInvestmentInRice-image-1"
          />
          <table className="ourInvestmentInRice-table-1">
            <tbody>
              <tr>
                <td>
                  <span className="ourInvestmentInRice-bold">2,000</span>
                  <br /> Strong farmer network - Dairy
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Column 3 */}
        <div className="ourInvestmentInRice-column">
          <img
            src={Seed}
            alt="Seed Paddy"
            className="ourInvestmentInRice-image-1"
          />
          <table className="ourInvestmentInRice-table-1">
            <tbody>
              <tr>
                <td>
                  <span className="ourInvestmentInRice-bold">1,000</span>
                  <br /> Out growers – Seed
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OurInvestmentInRice;
