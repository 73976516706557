import React from "react";
import "./AgriRiceMainBanner.css";
import AgriImage from "../../../../images/cropSegmentImage1.png";

export default function AgriRiceMainBanner() {
  return (
    <div>
      <div>
        <div className="AgriRiceMain">
          <img className="AgriRiceMain-home-image" alt="" src={AgriImage} />
          <div className="AgriRiceMain-image-text-box">
            <div className="AgriRiceMain-image-background-text">
              <h1 className="AgriRiceMain-image-topic">Quality Rice</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
