import React from "react";
import AgriRiceMainBanner from "../templates/OurSegments/AgriProduce/AgriRice/AgriRiceMainBanner";
import AboutRice from "../templates/OurSegments/AgriProduce/AgriRice/AboutRice";
import OurInvestmentInRice from "../templates/OurSegments/AgriProduce/AgriRice/OurInvestmentInRice";

export default function MarketCapitalization() {
  return (
    <div>
      <AgriRiceMainBanner />
      <AboutRice />
      <OurInvestmentInRice />
    </div>
  );
}
